<!--
 * @Author: Do not edit
 * @Date: 2021-07-22 14:04:36
 * @LastEditors: zhulin
 * @LastEditTime: 2021-09-23 14:09:42
 * @Description: Do not edit
 * @FilePath: \enfry-website\src\views\article.vue
-->
<template>
  <div class="article">
    <div class="head">
      <div class="col-title">{{listData.name}}</div>
      <div class="col-date">{{listData.time}}</div>
    </div>
    <div class="col-content">
      <p v-for="(item,index) in listData.content" :key="index" :class="{notextindend: item.type==='video',textright: item.text === 'right'}">
        <span class="imgs" v-if="item.type==='img'">
          <img :src="item.src" alt=""/>
        </span>
        <span class="txts" v-else-if="item.type==='txt'">
          {{item.src}}
        </span>
        <span class="titles" v-else-if="item.type==='title'">
          {{item.src}}
        </span>
        <span v-else-if="item.type==='video'" class="video-box">
          <iframe style="width:100%;height:100%" frameborder="0" :src="item.src||''" allowFullScreen="true"></iframe>
        </span>
        <span class="" v-else>
          {{item}}
        </span>
      </p>
    </div>
  </div>
</template>
<script>
import {zhuanyeList,canhuiList,shipinList} from "./article/article";

export default {
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      queryId:"",
      listData:{},
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      preload: "auto",
      controls: true,
      autoplay: "",
      videoURL:""
    };
  },
  mounted() {
    this.queryId = this.$route.query.id;
    let queryData =[];
    if(this.queryId.includes("zhuanye")){
      queryData = zhuanyeList;
    }
    if(this.queryId.includes("canhui")){
      queryData = canhuiList;
    }
    if(this.queryId.includes("shipin")){
      queryData = shipinList;
    }
    this.listData = queryData.find((item) => item.id === this.queryId);
  },
  methods: {
  }
};
</script>
<style lang="scss" scoped>
.notextindend{
  text-indent: 0;
}
.textright{
  text-align: right;
}
.titles{
  font-weight: bold;
}
.imgs{
  img{
    max-width:100%;
  }
}
.article{
  padding-top:100px;
  color:#333;
  min-height:880px;
  padding-bottom:60px;
  width:1200px;
  margin: 0 auto;
  text-align: left;
}
.head{
  border-bottom:1px solid #e5e5e5;
  height:60px;
  line-height:60px
}
.col-title{
  font-size:24px;
  float: left;
  display:inline-block
}
.col-date{
  color:#606060;
  font-size:12px;
  display:inline-block;
  text-align:right;
  float:right;
}
p{
  text-indent:24px;
  margin-top:25px;
  line-height:24px;
  font-size:14px;
  }
dd{
  margin:0
}
.video-box{
  height: 680px;
  width:100%;
  margin:0 auto;
  display: block;
}
@media (max-width: 750px) {
  .article{
    padding-top:50px;
    width:100%;
    min-height:600px;
  }
  .imgs img[data-v-5b5b10cc] {
    margin: 0;
    padding: 0;
    display: block;
  }
  .head{
    height: auto;
    line-height:34px;
    padding:0 20px;
    .col-title{
      float: none;
      font-size:22px;
      line-height:30px;
      display: block;
    }
    .col-date{
      float: none;
      display: block;
      text-align: left;
    }
  }
  .video-box{
    height:200px;
    // width: 100%;
    // margin-left: -20px;
  }
  p{
    width: 90%;
    margin-left: 5%;
  }
  .notextindend{
    margin-left:0;
    width:100%;
  }
}
</style>
